import React from "react"
import * as style from "../herobanner/style.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroBanner = () => {
  // Data
  const { sanityResourcesPage: data } = useStaticQuery(graphql`
    query resourceHeader {
      sanityResourcesPage {
        heroBanner {
          bannerTitle
          bannerSubHeading
          imageBackground {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `)

  return (
    <div className={style.wrapper}>
      <div className={style.svgMax}>
        <div className={style.wrapperInner}>
          <div className={style.textCont}>
            <div className={style.aboveWaveContent}>
              <h1>{data.heroBanner.bannerTitle}</h1>
              <p>{data.heroBanner.bannerSubHeading}</p>
            </div>
          </div>
          <div className={style.imgCont}>
            <GatsbyImage
              image={data.heroBanner.imageBackground.asset.gatsbyImageData}
              alt={data.heroBanner.imageBackground.asset.alt}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroBanner
